
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CcsMultiselect from "@/components/ccs-multiselect/index.vue";
import { getSeasons } from "@/core/data/season";
export default defineComponent({
  name: "SeasonMultiselect",
  components: {
    CcsMultiselect,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: false,
    },
    selectedValue: {
      required: false,
    },
    loading: {
      required: false,
    },
    isEdit: {
      required: false,
      defualt: true,
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const reRender = ref<any>(true);
    const selectedItem = ref<any>(props.modelValue);
    const seasonList = ref<Array<any>>([]);
    const seasonSelectedValue = ref<any>(props.selectedValue);
    const seasonListComputed = computed(() => {
      return seasonList.value.map((item) => ({
        ...item,
        name: item.name,
        value: item.id,
      }));
    });
    const searchSeason = (name = "") => {
      return new Promise((res) => {
        getSeasons(1, {name}).then((resObj: any) => {
          seasonList.value[0] = { ...seasonSelectedValue.value };

          seasonList.value.splice(
            !seasonList.value[0].id ? 0 : 1,
            seasonList.value.length,
            ...resObj.data.filter((item) => item.id != seasonList.value[0].id)
          );
          seasonList.value = [...seasonList.value];
          console.log(seasonList.value);
          res(true);
        });
      });
    };

    const selectSeason = (data) => {
      seasonSelectedValue.value = data;
      emit("update:modelValue", seasonSelectedValue.value?.id);
    };
    watch(
      () => props.isEdit,
      (newVal: any, oldVal) => {
        searchSeason().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    watch(
      () => props.modelValue,
      (newVal: any, oldVal) => {
        selectedItem.value = props.modelValue;
        reRender.value = false;
        setTimeout(() => {
          reRender.value = true;
        }, 100);
      }
    );
    watch(
      () => props.selectedValue,
      (newVal: any, oldVal) => {
        seasonSelectedValue.value = props.selectedValue;
        searchSeason().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    onMounted(() => {
      searchSeason();
    });
    return {
      selectedItem,
      seasonListComputed,
      searchSeason,
      selectSeason,
      reRender,
    };
  },
});
