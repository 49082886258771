import moment from "moment";
import ApiService from "../services/ApiService";
interface Design {
  id: number;
  code: string;
  cost: string;
  type: string;
  remark: string;
  totalMaterialCost: string;
  totalCuttingTime: string;
  totalCuttingCost: string;
  designCost: string;
  peelingCost: string;
  fusingCost: string;
  transportCost: string;
  otherExpCost: string;
  finalCost: string;
  grossCost: string;
  image: string;
  party: string;
  season_id: string;
  season_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const Designs: Array<Design> = [];

const deleteDesign = function (item: Design) {
  return ApiService.delete(`design/${item.id}/`);
};

const addDesign = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  for (const key in item) {
    formData.append(key, item[key]);
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`design/${id}/`, formData);
  } else {
    return ApiService.post("design/", formData);
  }
};

const exportDesignData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile("export/design/?design_id=" + ids, "design-data").then(
      (data) => res(data)
    );
  });
};
const exportPDFDesigns = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/design/?design_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const designObjClone = function () {
  return {
    id: 0,
    code: "",
    cost: "0",
    type: "",
    remark: "",
    totalMaterialCost: "0",
    totalCuttingTime: "0",
    totalCuttingCost: "0",
    designCost: "0",
    peelingCost: "0",
    fusingCost: "0",
    transportCost: "0",
    otherExpCost: "0",
    finalCost: "0",
    grossCost: "0",
    image: "",
    party: "",
    created_by: "",
    season_id: "",
    season_data: {},
  };
};
const jsonToInterface = function (item: any) {
  const newItem: Design = designObjClone();
  newItem.id = item.id;
  newItem.code = item.code;
  newItem.cost = item.cost;
  newItem.type = item.type;
  newItem.remark = item.remark;
  newItem.totalMaterialCost = item.totalMaterialCost;
  newItem.totalCuttingTime = item.totalCuttingTime;
  newItem.totalCuttingCost = item.totalCuttingCost;
  newItem.designCost = item.designCost;
  newItem.peelingCost = item.peelingCost;
  newItem.fusingCost = item.fusingCost;
  newItem.transportCost = item.transportCost;
  newItem.otherExpCost = item.otherExpCost;
  newItem.finalCost = item.finalCost;
  newItem.grossCost = item.grossCost;
  newItem.image = item.image;
  newItem.party = item.party;
  newItem.season_id = item.season_id;
  newItem.season_data = item.season_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getDesignById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`design/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getDesigns = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      party: searchData.party_id || "",
      season_id: searchData.season_id || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`design/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const cloneDesign = function (id) {
  return ApiService.post(`design/${id}/clone`, { id });
};
const jsonToInterfaceDesign = jsonToInterface;
const getEmptyObjOfDesign = designObjClone;
export {
  Design,
  getDesigns,
  deleteDesign,
  addDesign,
  getDesignById,
  jsonToInterfaceDesign,
  designObjClone,
  exportDesignData,
  exportPDFDesigns,
  getEmptyObjOfDesign,
  cloneDesign,
};

export default Designs;
