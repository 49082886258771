
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CcsMultiselect from "@/components/ccs-multiselect/index.vue";
import { getPartyById, getPartys } from "@/core/data/party";
export default defineComponent({
  name: "PartyMultiselect",
  components: {
    CcsMultiselect,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: false,
    },
    selectedValue: {
      required: false,
    },
    loading: {
      required: false,
    },
    isEdit: {
      required: false,
      defualt: true,
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const reRender = ref<any>(true);
    const selectedItem = ref<any>(props.modelValue);
    const partyList = ref<Array<any>>([]);
    const partySelectedValue = ref<any>(props.selectedValue);
    const partyListComputed = computed(() => {
      return partyList.value.map((item) => ({
        ...item,
        name: item.companyName,
        value: item.id,
      }));
    });
    const searchParty = (name = "") => {
      return new Promise((res) => {
        getPartys(1, { name }).then((resObj: any) => {
          partyList.value[0] = { ...partySelectedValue.value };

          partyList.value.splice(
            !partyList.value[0].id ? 0 : 1,
            partyList.value.length,
            ...resObj.data.filter((item) => item.id != partyList.value[0].id)
          );
          partyList.value = [...partyList.value];
          if (
            selectedItem.value &&
            !partyList.value.some((item) => item.id === selectedItem.value)
          ) {
            getPartyById(selectedItem.value).then((resObj: any) => {
              partyList.value.push(resObj);
            });
          }
          console.log(partyList.value);
          res(true);
        });
      });
    };

    const selectParty = (data) => {
      partySelectedValue.value = data;
      emit("update:modelValue", partySelectedValue.value?.id);
    };
    watch(
      () => props.isEdit,
      (newVal: any, oldVal) => {
        searchParty().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    watch(
      () => props.modelValue,
      (newVal: any, oldVal) => {
        selectedItem.value = props.modelValue;
        reRender.value = false;
        setTimeout(() => {
          reRender.value = true;
        }, 100);
      }
    );
    watch(
      () => props.selectedValue,
      (newVal: any, oldVal) => {
        partySelectedValue.value = props.selectedValue;
        searchParty().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    onMounted(() => {
      searchParty();
    });
    return {
      selectedItem,
      partyListComputed,
      searchParty,
      selectParty,
      reRender,
    };
  },
});
